require("../css/plugin.scss");
import 'aos/dist/aos.css';
import "@tarekraafat/autocomplete.js/dist/css/autoComplete.02.css";

import AOS from 'aos';
import autoComplete from "@tarekraafat/autocomplete.js";
import 'flickity/dist/flickity.min.css';
import Flickity from 'flickity';

//================================================================
//          ADMIN
//================================================================
/**
 * Init des Animations On Scroll
 */
AOS.init({
    offset: 200,
    duration: 600,
    easing: 'ease-in-out',
    once: true,
    disable: 'mobile'
});

//================================================================
/**
 * Autocomplete de recherche de page sur la section admin
 */
let autocompleteFields = document.querySelectorAll('.oct_parcours_plugin_field_autocomplete');

if (autocompleteFields !== null) {
    let url = window.location.origin + '/wp-admin/admin-ajax.php',
        autocompleteFieldsToRemove = document.querySelectorAll('.oct_parcours_plugin_field_autocomplete--remove');

    autocompleteFields.forEach(function (field) {
        field.addEventListener('keypress', function (e) {
            return e.which !== 13;
        });

        let autocompleteConfig = {
            selector: '#' + field.id,
            data: {
                src: async function (query) {
                    let req = url + '?action=oct_post_search&term=' + query,
                        fetchInit = {
                            method: 'GET'
                        };

                    return await fetch(req, fetchInit)
                        .then(function (response) {
                            return response.json();
                        })
                        .then(function (data) {
                            return data;
                        });
                },
                keys: ['label'],
            },
            resultsList: {
                id: 'oct_result_list',
                destination: '#' + field.id,
            },
            events: {
                input: {
                    selection: function (event) {
                        let result = event.detail.selection.value;

                        field.value = result.label;
                        field.parentNode.parentNode.querySelector('.oct_parcours_plugin_field_autocomplete--id').value = result.id;
                    }
                }

            }
        };

        let autoCompleteJS = new autoComplete(autocompleteConfig);
    });

    autocompleteFieldsToRemove.forEach(function (link) {
        link.addEventListener('click', function () {
            //alert('Etes vous sûr de vouloir supprimer ce lien ?');
            this.parentNode.parentNode.querySelector('.oct_parcours_plugin_field_autocomplete--id').value = '';
            this.parentNode.parentNode.querySelector('.oct_parcours_plugin_field_autocomplete').value = '';
            document.querySelector('#oct_parcours_configuration_form').submit();
        });
    });
}

//================================================================
/**
 * Gestion status postType parcours (publish / draft)
 */
let octParcoursStatusToggle = document.querySelectorAll('.oct_admin_parcours__item__status--toggle');

if (octParcoursStatusToggle.length > 0) {
    let octParcoursItemsContainer = document.querySelector('.oct_admin_parcours__items'),
        octAlert = octParcoursItemsContainer.querySelector('.oct_alert');

    if (octAlert !== null) {
        octAlert.remove();
    }

    octParcoursStatusToggle.forEach(function (toggle) {
        toggle.addEventListener('click', function (e) {
            let httpRequest = new XMLHttpRequest(),
                thisInput = e.currentTarget,
                documentId = thisInput.dataset.item,
                status = thisInput.checked ? 1 : 0,
                url = '/wp-json/portail-parcours/v1/toggle-status/' + documentId + '/' + status;

            httpRequest.onreadystatechange = function () {
                if (httpRequest.readyState === XMLHttpRequest.DONE) {
                    if (httpRequest.status !== 200) {
                        alert('Il y a eu un problème avec la requête.');
                    }
                    else {
                        document.querySelector('.oct_admin_parcours__table').insertAdjacentHTML('beforebegin', '<div class="oct_alert oct_alert--success">Le status du parcours a bien été mis à jour</div>');

                        window.setTimeout(function () {
                            Opacity.fadeOut(octParcoursItemsContainer.querySelector('.oct_alert'));
                        }, 5000);
                    }
                }
            };

            httpRequest.open('GET', url, true);
            httpRequest.send();

        });
    });
}

//================================================================
//          FRONT
//================================================================
var width = viewport().width,
    device = which_device(width),
    timeoutResize;

window.addEventListener("resize", function () {
    clearTimeout(timeoutResize);
    timeoutResize = setTimeout(function () {
        device = which_device(viewport().width);
        initSlider(device);
    }, 100);
});

setTimeout(function () {
    initSlider(device);
}, 500);

function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
};

function which_device(width) {
    if (width > 544) {
        if (width > 1599) {
            return 5; // desktop large
        } else if (width > 1395) {
            return 4; // desktop
        } else if (width > 1024) {
            return 3; // laptop
        } else if (width > 767) {
            return 2; // tablet
        }
        else {
            return 1; //mobile extra
        }
    }
    return 0; // mobile
};

/**
 * Cookies
 */
function bake_cookie(name, value) {
    var cookie = [name, '=', JSON.stringify(value), '; domain=.', window.location.host.toString(), '; path=/;'].join('');
    document.cookie = cookie;
}

function read_cookie(name) {
    var result = document.cookie.match(new RegExp(name + '=([^;]+)'));
    result && (result = JSON.parse(result[1]));
    return result;
}

function delete_cookie(name) {
    document.cookie = [name, '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; path=/; domain=.', window.location.host.toString()].join('');
}

//================================================================
/**
 * Effets FadeIn / FadeOut
 */
var Opacity = {
    opacityDefaultDuration: 250,  // default duration
    fadeIn: function (target, duration, display = 'block') {
        if (typeof target !== "undefined" && target !== null) {
            target.style.opacity = 0;
            target.style.display = display;
            if (typeof duration !== "number" || duration == 0) {
                duration = Opacity.opacityDefaultDuration;
            }
            var step = 10 / duration;
            Opacity._setOpacity(target, step, 'fadeIn');
        }
    },

    fadeOut: function (target, duration) {
        if (typeof target !== "undefined" && target !== null) {
            target.style.opacity = 1;

            if (typeof duration !== "number" || duration == 0) {
                duration = Opacity.opacityDefaultDuration;
            }
            var step = 10 / duration;
            Opacity._setOpacity(target, step, 'fadeOut');
        }
    },

    _setOpacity: function (target, step, type) {
        switch (type) {
            case 'fadeOut': {
                var op = parseFloat(target.style.opacity) - step;
                if (op > 0) {
                    target.style.opacity = op;
                    setTimeout(function () {
                        Opacity._setOpacity(target, step, 'fadeOut');
                    }, 10);
                } else {
                    target.style.display = 'none';
                }
                break;
            }
            case 'fadeIn': {
                var op = step + parseFloat(target.style.opacity);
                if (op < 1) {
                    target.style.opacity = op;
                    setTimeout(function () {
                        Opacity._setOpacity(target, step, 'fadeIn');
                    }, 10);
                } else {
                    target.style.opacity = 1;
                }
                break;
            }
        }
    }
}

//================================================================
/**
 * Affichage Formulaire pour télechargement Parcours
 */
let octParcoursToggleForm = document.querySelectorAll('.oct_parcours__item a.oct_document_download_toggle'),
    octParcoursContainers = document.querySelectorAll('.oct_parcours'),
    octParcoursFormContainer,
    octParcoursForm;

/* if (octParcoursContainers.length > 0) {
    octParcoursContainers.forEach(function (octParcoursSection) {
        window.setTimeout(function () {
            Opacity.fadeOut(octParcoursSection.querySelector('.oct_alert'));
        }, 6000);
    });
} */

if (octParcoursToggleForm.length > 0) {
    octParcoursToggleForm.forEach(function (toggleForm) {
        toggleForm.addEventListener('click', function (e) {
            e.preventDefault();
            octParcoursFormContainer = document.querySelector('#oct_parcours_download_' + toggleForm.dataset.plugin),
                octParcoursForm = octParcoursFormContainer.querySelector('form');

            octParcoursFormContainer.querySelector('.oct_parcours_download_form__background').addEventListener('click', function () {
                Opacity.fadeOut(octParcoursFormContainer);
            });

            octParcoursFormContainer.querySelector('.oct_icon_close').addEventListener('click', function () {
                Opacity.fadeOut(octParcoursFormContainer);
            });

            // On fait apparaitre le formulaire
            Opacity.fadeIn(octParcoursFormContainer);
            window.setTimeout(function () {
                octParcoursFormContainer.scrollIntoView({
                    behavior: "smooth",
                });
            }, 500);

            // On set un cookie avec l'id du document
            bake_cookie('parcours-download-id', this.dataset.id);

            octParcoursForm.addEventListener('submit', function (e) {
                e.preventDefault();
                let httpRequest = new XMLHttpRequest(),
                    documentId = read_cookie('parcours-download-id'),
                    url = '/wp-json/portail-parcours/v1/download-document/' + documentId,
                    formDatas = new FormData(e.currentTarget),
                    octAlert = octParcoursFormContainer.querySelector('.oct_alert');

                if (octAlert !== null) {
                    octAlert.remove();
                }

                httpRequest.onreadystatechange = function () {
                    if (httpRequest.readyState === XMLHttpRequest.DONE) {
                        if (httpRequest.status === 200) {
                            let response = JSON.parse(httpRequest.response),
                                messageType,
                                message,
                                errors;

                            if (response.hasOwnProperty('error') && response.error === true) {
                                messageType = 'error';

                                message = '<ul>',
                                    errors = JSON.parse(response.message);

                                for (let [field, error] of Object.entries(errors)) {
                                    message += '<li>' + error + '</li>';
                                }
                                message += '</ul>';
                            }
                            else {
                                messageType = 'success';
                                message = response.message;
                                octParcoursForm.remove();
                            }

                            octParcoursFormContainer.querySelector('.oct_parcours_download_form__content').insertAdjacentHTML('afterbegin', '<div class="oct_alert oct_alert--' + messageType + '">' + message + '</div>');

                            if (messageType === 'success') {
                                octParcoursFormContainer.querySelector('.oct_parcours_download_form__background').addEventListener('click', function () {
                                    window.location.href = response.redirectUrl;
                                });

                                octParcoursFormContainer.querySelector('.oct_icon_close').addEventListener('click', function () {
                                    window.location.href = response.redirectUrl;
                                });

                                octParcoursFormContainer.querySelector('a.oct_dl').addEventListener('click', function () {
                                    window.setTimeout(function () {
                                        window.location.href = response.redirectUrl;
                                    }, 100);
                                });
                            }
                        } else {
                            alert('Il y a eu un problème avec la requête.');
                        }
                    }
                };

                httpRequest.open('POST', url, true);
                httpRequest.send(formDatas);
            });
        });
    });
}

//================================================================
/**
 * SLIDER a la place de la pagination
 */
let octParcoursSliders = document.querySelectorAll('.oct_parcours .oct_slider_container'),
    flktySliders = [];

function initSlider(device) {
    if (octParcoursSliders.length > 0) {
        if (flktySliders.length > 0) {
            flktySliders.forEach(function (slider) {
                slider.destroy();
                flktySliders = [];
            });
        }

        //Init des sliders Parcours
        octParcoursSliders.forEach(function (octSlider) {
            let itemsperSlide = JSON.parse(octSlider.dataset.items),
                slideWrapper = octSlider.querySelectorAll('.oct_parcours__items');

            // on regarde si le HTML a déja été construit par rapport au nb item par slide, pour supprimer le wrapper au resize et reconstruire le code
            if (slideWrapper.length > 0) {
                slideWrapper.forEach(wrapper => {
                    wrapper.replaceWith(...wrapper.childNodes);
                });
            }

            //making final arrays for the children with chunk size(nbItemsperSlide)
            //nbItemsperSlide varie en fonction du type de device
            //widescreen(> 1599px => 5), desktop(> 1395px => 4), laptop(> 1024px => 3), tablet(> 767px => 2), mobile_extra(> 544px => 1), mobile(0)
            let items = octSlider.querySelectorAll('.oct_parcours__item'),
                finalArray = chunk(items, parseInt(itemsperSlide[device]));

            //wrapping the chunks with div.oct_parcours__items
            finalArray.map(towrap =>
                towrap.reduce((acc, el) =>
                    (acc.appendChild(el), acc), document.createElement('div')
                )
            ).forEach(el => {
                el.className = "oct_parcours__items";
                octSlider.appendChild(el);
            });

            // création de la pagination
            let sliderPagination = octSlider.parentNode.querySelector('.oct_slider_pagination ul'),
                slides = octSlider.querySelectorAll('.oct_parcours__items'),
                prevLink = document.createElement('li'),
                nextLink = document.createElement('li');

            // Si la pagination a déja été construite on réinitialise au resize
            if (sliderPagination.innerHTML !== '') {
                sliderPagination.innerHTML = '';
            }

            //on ajout le lien "précédent"
            prevLink.className = "oct_pagination_to--prev";
            prevLink.innerHTML = '<span>&lt;</span>';
            sliderPagination.appendChild(prevLink);

            //on ajoute les liens vers chaque slide
            slides.forEach((slide, index) => {
                let itemPaginationlink = document.createElement('li');
                itemPaginationlink.className = "oct_slider_pagination__index";
                itemPaginationlink.dataset.index = index;
                itemPaginationlink.innerHTML = index + 1;
                sliderPagination.appendChild(itemPaginationlink);
            });

            //on ajout le lien "suivant"
            nextLink.className = "oct_pagination_to--next";
            nextLink.innerHTML = '<span>&gt;</span>';
            sliderPagination.appendChild(nextLink);

            let options = {
                cellSelector: '.oct_parcours__items',
                cellAlign: 'left',
                wrapAround: false,
                imagesLoaded: true,
                pageDots: false,
                prevNextButtons: false,
                adaptiveHeight: false,
                draggable: false,
                on: {
                    ready: function () {
                        let self = this,
                            octSliderNavLinks = sliderPagination.querySelectorAll('.oct_parcours li.oct_slider_pagination__index');
                        // on ajoute la classe "active" sur le bouton de navigation correspondant au slide actif à l'init
                        if (octSliderNavLinks.length > 0) {
                            octSliderNavLinks.forEach(function (navLink) {
                                let thisSlider = navLink.parentNode.parentNode.parentNode.querySelector('.oct_slider_container');

                                if (typeof navLink.dataset.index !== 'undefined' && parseInt(navLink.dataset.index) === self.selectedIndex && self.element.id === thisSlider.id) {
                                    navLink.classList.add('active');
                                }
                            });
                            InitPrevNext(self);
                        }
                    }
                }
            };

            let flktySlider = new Flickity(octSlider, options);
            flktySliders.push(flktySlider);
        });

        // Gestion navigation des sliders
        if (flktySliders.length > 0) {
            let previousButton = document.querySelectorAll('.oct_parcours li.oct_pagination_to--prev'),
                nextButton = document.querySelectorAll('.oct_parcours li.oct_pagination_to--next'),
                octSlidersNavLinks = document.querySelectorAll('.oct_parcours li.oct_slider_pagination__index');

            prevButtonAction();
            nextButtonAction();

            // Selection d'un slide au click sur la navigation
            octSlidersNavLinks.forEach(function (navLink) {
                navLink.addEventListener('click', function (e) {
                    e.preventDefault();
                    if (flktySliders.length > 0) {
                        var thisSlider = e.target.parentNode.parentNode.parentNode.querySelector('.oct_slider_container');
                        flktySliders.forEach(function (slider) {
                            if (thisSlider.id === slider.element.id) {
                                slider.select(e.target.dataset.index);
                                InitPrevNext(slider);
                            }
                        });
                    }
                });
            });

            //maj des classe "active" sur les bouton de navigation des sliders lorsqu'un slide est sélectionné
            flktySliders.forEach(function (slider) {
                slider.on('select', function (index) {
                    let self = this;

                    if (octSlidersNavLinks.length > 0) {
                        octSlidersNavLinks.forEach(function (navLink) {
                            let previousSelectedButton = navLink.parentNode.querySelector('.active'),
                                thisSlider = navLink.parentNode.parentNode.parentNode.querySelector('.oct_slider_container');

                            if (typeof navLink.dataset.index !== 'undefined' && parseInt(navLink.dataset.index) === index && self.element.id === thisSlider.id) {
                                previousSelectedButton.classList.remove('active');
                                navLink.classList.add('active');
                                InitPrevNext(self);
                            }
                        });
                    }
                });
            });

            // click sur bouton précédent
            function prevButtonAction() {
                if (previousButton.length > 0) {
                    previousButton.forEach(function (button) {
                        button.addEventListener('click', function (e) {
                            e.preventDefault();

                            if (flktySliders.length > 0) {
                                var thisSlider = this.parentNode.parentNode.parentNode.querySelector('.oct_slider_container');

                                flktySliders.forEach(function (slider) {
                                    if (thisSlider.id === slider.element.id) {
                                        slider.previous();
                                        InitPrevNext(slider);
                                    }
                                });
                            }
                        });
                    });
                }
            }

            // click sur bouton suivant
            function nextButtonAction() {
                if (nextButton.length > 0) {
                    nextButton.forEach(function (button) {
                        button.addEventListener('click', function (e) {
                            e.preventDefault();

                            if (flktySliders.length > 0) {
                                var thisSlider = this.parentNode.parentNode.parentNode.querySelector('.oct_slider_container');
                                flktySliders.forEach(function (slider) {
                                    if (thisSlider.id === slider.element.id) {
                                        slider.next();
                                        InitPrevNext(slider.element.id);
                                    }
                                });
                            }
                        });
                    });
                }
            }
        }

        // chunk function to make groups for every slider's childrens
        function chunk(array, size) {
            let arr = [];
            for (let i = 0; i < array.length; i += size) {
                let newSlicedArray = Array.prototype.slice.call(array, i, i + size);
                arr.push(newSlicedArray);
            }
            return arr;
        }

        // Affichage ou non des fleche prev / next pour le slider correspondant
        function InitPrevNext(slider) {
            let thisSlider = document.querySelector('#' + slider.element.id),
                sliderPagination = thisSlider.parentNode.querySelector('.oct_slider_pagination'),
                octSliderNav = thisSlider.parentNode.querySelectorAll('li.oct_slider_pagination__index');

            if (slider.cells.length <= 1) {
                // cache la navigation si un seul slide
                sliderPagination.style.display = 'none';
            } else if (octSliderNav.length > 0) {
                let shouldSkip = false;
                sliderPagination.style.display = 'block';

                octSliderNav.forEach(function (navLink) {
                    if (shouldSkip) {
                        return;
                    }
                    if (navLink.matches('.active') && parseInt(navLink.dataset.index) === 0) {
                        navLink.parentNode.querySelector('.oct_pagination_to--prev').style.display = 'none';
                        navLink.parentNode.querySelector('.oct_pagination_to--next').style.display = 'inline-block';
                        shouldSkip = true;
                        return;
                    }
                    else {
                        navLink.parentNode.querySelector('.oct_pagination_to--prev').style.display = 'inline-block';
                        shouldSkip = false;
                    }

                    if (navLink.matches('.active') && parseInt(navLink.dataset.index) === (octSliderNav.length - 1)) {
                        navLink.parentNode.querySelector('.oct_pagination_to--next').style.display = 'none';
                        shouldSkip = true;
                        return;
                    }
                    else {
                        navLink.parentNode.querySelector('.oct_pagination_to--next').style.display = 'inline-block';
                        shouldSkip = false;
                    }
                });
            }
        }
    }
}